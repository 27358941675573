// @import "../../../../assets/scss/_fonts.scss";
@import "../../../../assets/scss/_variables.scss";
@import "../../../../assets/scss/_mixins.scss";

.prizes-container {
    ul {
        li {
            margin-bottom: 20px;
        }
    }
}

 .exchanged-prizes-container{
    margin-top: 20px;
    border: 1px solid $brandBorderGrey;
    border-radius: $borderRadius;
    padding: 25px;
    background-color: $brandWhite;
    text-align: left;
    
    span {
        color:$brandMediumGrey;
    }

    .prize-item{
        display: flex; 
        justify-content: space-between;
    }
}