@import "../../../../assets/scss/_fonts.scss";
@import "../../../../assets/scss/_variables.scss";
@import "../../../../assets/scss/_mixins.scss";

.settings-options-list {
  max-width: 500px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 5%;
  margin-bottom: 7%;

  @include phone-large {
    max-width: 95%;
    margin-left: auto;
    margin-right: auto;
  }

  .option {
    border-bottom: 1px solid $brandDeepblue;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    position: relative;

    &:after {
      content: "";
      display: inline-block;
      background: url(../../../../assets/images/icons/arrow-left-g.svg) no-repeat;
      width: 28px;
      height: 28px;
      transform: rotate(180deg);
      position: absolute;
      right: 0px;
    }

    .option-icon {
      display: inline-block;
      width: 25px;
    }

    button, a {
      font-family: $title;
      color: $brandDeepblue;
      font-weight: 500;
      font-size: 1.1rem;
      border: 0px;
      background: transparent;
      padding: 15px;  
      transition: color 300ms ease-in-out;

      @include phone-large {
        font-size: 1.3rem;
      }

      &:focus {
        outline: 0px;
       }

      &:hover {
        color: $brandOrange;
        cursor: pointer;
          text-decoration: none;
      }
    }
  }
}

.delete-account-details {
  max-width: 500px;
}

.delete-actions {
  display: flex;
  justify-content: space-between;
  margin-top: 10%;

  button {
    max-width: 45%;
  }
}
.success-text{
  font-family: $title;
	font-weight: bold;
	color: $brandDeepblue;

}

