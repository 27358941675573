@import "../../assets/scss/_fonts.scss";
@import "../../assets/scss/_variables.scss";
@import "../../assets/scss/_mixins.scss";

.sidebar {
	max-width: 200px;
	width: 100%;
	background-color: $brandDeepblue;
	position: fixed;
	height: 100vh;
	//@include flexColumnCenter;
	display: flex;
	flex-direction: column;
	align-items: center;

	@include desktop-medium {
		max-width: 160px;
	}

	@include desktop-small {
		max-width: 135px;
	}

	@include tablet-medium {
		bottom: 0px;
		flex-direction: row;
		justify-content: space-between;
		max-width: 100%;
		width: 100%;
		max-height: 100px;
		z-index: 999;
	}

	@include tablet-small {
		justify-content: center;
		max-height: 85px;
	}

	@include phone-small {
		max-height: 65px;
	}

	.menu {
		margin-top: 20%;

		@include tablet-large-portrait {
			position: static;
		}

		@include tablet-medium {
			position: static;
			display: flex;
			justify-content: space-between;
			align-items: center;
			margin-right: 20px;
			width: 60%;
			margin-top: 0px;
		}

		@include tablet-small {
			width: 85%;
			margin-right: 0px;
		}

		.menu-item {
			@include desktop-small {
				margin-top: 25px;
			}

			@include tablet-medium {
				margin-top: 0px;
			}
		}
	}

	.logo {
		width: 82%;
		margin-top: $separation;
		// position: absolute;
		// top: 30px;

		@include tablet-medium {
			width: 185px;
    	position: static;
			margin-left: 20px;
			margin-top: 0px;	
		}

		@include tablet-small {
			display: none;
		}
	}
 }