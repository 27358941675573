@import "../../assets/scss/_fonts.scss";
@import "../../assets/scss/_variables.scss";
@import "../../assets/scss/_mixins.scss";


.overlay {
	background-color: rgba(0,0,0,0.5);
	position: fixed;
	top:0;
	right: 0;
	width: 100vw;
	height: 100vh;
	display: flex;
	align-items: center;
	justify-content: center;
	z-index: 999;
	@include phone-large{
		align-items: flex-start;
		padding-top: 20px;
	}

	.modal-container {
		background-color: $brandWhite;
		// max-width: 600px;
		min-width: 500px;
		padding: 30px;
		padding-left: 50px;
		padding-right: 50px;
		position: relative;
		border-radius: 2rem;
        animation: modalanima 300ms 1;
        transform: translateY(10px);
        opacity: 0;
        animation-fill-mode: forwards;
        -webkit-animation-fill-mode: forwards;
        animation-timing-function: ease-in-out;
        
        @keyframes modalanima{
            0%{transform: translateY(10px); opacity: 0; transition: 300ms ease-in-out;}
            100%{transform: translateY(0px); opacity: 1; transition: 300ms ease-in-out;}
        }

		@include desktop-small {
			padding: 20px;
    	padding-left: 35px;
    	padding-right: 35px;
		}

		@include tablet-small {
			min-width: 90%;
			max-width: 90%;
		}

		.modal-content {
			// overflow: auto;
			overflow-x: hidden;
			text-align: left;
			padding-top: 5%;
			padding-bottom: 5%;
			text-align: center;

			@include phone-large {
				max-height: calc(100vh - 100px);
			}

			h2 {
				margin-bottom: 20px;
			}

			h3{
				@include title;
				color: $brandDeepblue;
				font-size: 1.6rem !important;
				//margin-bottom: 45px;
			}

			p{
				@include desktop-small {
					font-size: 1.1rem;
				}
			}
		}
	}

	.close-button {
		background-image: url(../../assets/images/icons/close-g.svg);        
		background-color: transparent;
		border: none;
		border-radius: 100%;
		height: 25px;
		width: 25px;
		color: $brandWhite;
		position: absolute;
		right: 18px;
		top: 18px;
		z-index: 1000;
		&:hover {
			background-image: url(../../assets/images/icons/close-o.svg);        
			cursor: pointer;
		}

		&:focus {
			outline: 0px;
	 	}
	}

	.success-text {
		font-family: $title;
		font-weight: bold;
		color: $brandDeepblue;
	}
}

