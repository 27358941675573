.prizes-list-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.availability-title {
  font-size: 1.3rem;
  margin-top: 35px;
  margin-bottom: 10px;
}