@import "../../assets/scss/_fonts.scss";
@import "../../assets/scss/_variables.scss";
@import "../../assets/scss/_mixins.scss";

.card {
	border-radius: $borderRadius;
	padding: 25px;
	// max-width: 50%;
	background-color: $brandWhite;
	max-width: 24rem;
	margin: auto;
	text-align: left;
	border: 1px solid $brandBorderGrey;

	p {
		color:$brandMediumGrey;
	}

	&.forgot-card, &.setpassword-card {
			max-width: 35rem;
	}
}

.cards-container {
	display: flex;

	@include tablet-small {
		flex-direction: column;
	}

	.card {
		width: 31%;
		min-height: 430px;
		display: flex;
		flex-direction: column;
		justify-content: space-evenly;
		align-items: center;
		
		@include desktop-large {
			padding: 3%;
			min-height: 360px;
		}

		@include desktop-medium {
			min-height: 300px;
			padding: 4%;
		}

		@include tablet-small {
			width: 100%;
			flex-direction: row;
			align-items: center;
			justify-content: space-between;
			max-width: 95%;
			min-height: auto;
			padding: 8px;
			padding-left: 15px;
			padding-right: 15px;
			margin-bottom: 15px;
		}

		@include phone-small {
			padding: 8px;
			padding-left: 10px;
			padding-right: 10px;
			margin-bottom: 10px;
		}

		.button-app {
			flex-grow: 0;
			flex-shrink: 0;

			@include tablet-small {
				display: block;
				width: 45px;
				height: 45px;
			}

			@include phone-large {
				width: 40px;
				height: 40px;
			}

			@include phone-small {
				width: 30px;
				height: 30px;
				border-radius: 10px;
			}

			.button-label {
				@include tablet-small {
					display: none;
				}
			}

			.button-icon {
					right: 10px;

					@include phone-small {
						right: 2px;
						top:15%;
					}
			}
		}
			
		h3{
			@include title;
			color: $brandDeepblue;
			font-size: 1.3rem;
			text-align: center;
			height: 70px;
			max-width: 190px;
			display: flex;
			justify-content: center;
			align-items: center;
			margin-bottom: 0px;
			
			@include desktop-large {
				width: 100%;
			}

			@include tablet-small {
				height: 50px;
				font-size: 1.5rem;
			}

			@include phone-large {
				font-size: 1.3rem;
				max-width: 130px;
			}

			@include phone-small {
				height: auto;
				width: 45%;
			}
		}
  }
}