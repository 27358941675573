@import "../../../assets/scss/_fonts.scss";
@import "../../../assets/scss/_variables.scss";
@import "../../../assets/scss/_mixins.scss";

.forgot-card {
	margin-bottom: 15px;
	padding-top: 10px;
	text-align: center;
	border: none !important;
	border-radius: 2rem;

	h1 {
		@include title;
		color: $brandDeepblue;
		font-size: 2rem !important;
	}
	
	.form-group{
		max-width: 350px;
		margin: auto;
	}

	button {
		margin-top: 20px;
	}
}
