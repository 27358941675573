@import "../../../assets/scss/_fonts.scss";
@import "../../../assets/scss/_variables.scss";
@import "../../../assets/scss/_mixins.scss";

.title-container {
  text-align: center;
}

.contact-container {
  display: flex;
  justify-content: space-between;
  max-width: 70%;
  margin: auto;

  @include desktop-large {
    max-width: 75%;
  }

  @include tablet-small {
    max-width: 95%;
    flex-direction: column;
  }

  .card {
    border: 1px solid $brandBorderGrey;
    min-height: 430px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 2rem;
    width: 45%;
    margin: initial;

    @include desktop-large {
      width: 45%;
      min-height: 350px;
    }

    @include desktop-medium {
      width: 45%;
    }

    @include desktop-small {
      width: 48%;
      min-height: 340px;
    }

    @include tablet-small {
      width: 100%;
      flex-direction: row;
      align-items: center;
      max-width: 100%;
      min-height: 80px;
      padding: 2%;
      padding-left: 15px;
      padding-right: 15px;
      margin-bottom: 15px;
      position: relative;
    }

    @include phone-large {
      min-height: 70px;
      align-content: center;
      padding: 8px;
      padding-left: 15px;
      padding-right: 15px;
    }

    h3 {
      font-family: $title;
      color: $brandDeepblue;
      font-weight: bold;
      text-align: center;
      font-size: 1.3rem;

      @include desktop-medium {
        margin-bottom: 0px;
      }

      @include tablet-small {
        font-size: 1.5rem;
        width: 120px;
        margin-left: auto;
        margin-right: auto;
        transform: translate(-10px);
      }

      @include phone-large {
        width: 45%;
        margin-bottom: 0px;
        font-size: 1.3rem;
      }
    }

    .button-app {
      @include tablet-small {
        position: absolute;
        width: 100%;
        max-width: 95%;
        left: 0;
        top: 0;
        height: 72px;
        background: url(../../../assets/images/icons/arrow-right-o.svg) no-repeat transparent;
        background-position: right center;
        background-size: 25px;
      }

      @include phone-large {
        height: 66px;
      }

      @include phone-small {
        height: 50px;
      }

      .button-icon {
        @include tablet-small {
          display: none;
        }
      }

      .button-label {
        @include tablet-small {
          display: none;
        }
      }
    }
  }

  .icon-image {
    width: 160px;

    @include desktop-large {
      width: 120px;
    }

    @include tablet-small {
      width: 50px;
    }

    @include phone-large {
      width: 35px;
    }
  }
  .overlay{
    .modal-container {
      padding: 0 15px 0 15px;
      .top-modal-info{
        p {
          font-size: 1rem;
        }
      }
      .bottom-space{
          font-size: 1rem !important;
      }
      .button-app {
        @include tablet-small {
          width: 100%;
        }
    
        .button-label {
          @include tablet-small {
            display: block !important;
          }
        }
      }
    
    }
  
  }
}



.success-text {
  font-family: $title;
  font-weight: bold;
  color: $brandDeepblue;
}