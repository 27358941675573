@import "../../../assets/scss/_fonts.scss";
@import "../../../assets/scss/_variables.scss";
@import "../../../assets/scss/_mixins.scss";

.legal-info {
	.card {
		border-radius: 2rem;
		max-width: 900px;
		border: none;
		border-radius: 2rem;

		@include tablet-large {
			max-width: 600px;
		}

		@include tablet-small {
			max-width: 95vw;
		}
	}

	.logo-container {
		position: static;
		width: 100%;
		height: auto;

		a {
			max-width: 300px;
			display: block;
			padding: 20px;
		}
	}

	h1 {
		@include title;
		color: $brandDeepblue;
		margin-bottom: 20px;
	}

	h2 {
		@extend h1;
		font-size: 1.6rem;
	}

	h3 {
		@extend h1;
		font-size: 1.2rem;
	}
	ul {
		margin-bottom: 20px;
	}
}
