@import "../../assets/scss/_fonts.scss";
@import "../../assets/scss/_variables.scss";
@import "../../assets/scss/_mixins.scss";

.select_component_container {
	position: relative;
	transition: all 0.3s;

	.select_componet_style {
		display: flex;
		flex-direction: row;
		flex-wrap: nowrap;
		align-items: center;
		justify-content: space-between;
		gap: 10px;
		padding: 10px;
		border-radius: 6px;
		background-color: #ffffff;
		cursor: pointer;
		transition: all 0.3s;

		.prefijo_style {
			font-size: 11px;
			font-weight: 400;
			line-height: 13.2px;
			color: #0D4B93;
			transition: all 0.3s;
			font-family: $text;

			@include tablet-medium {
				font-size: 17px;
			}
		}

		.label_style {
			font-family: $menu;
			font-size: 11px;
			font-weight: 900;
			line-height: 13.2px;
			color: #0D4B93;
			transition: all 0.3s;

			@include tablet-medium {
				font-size: 17px;
			}
		}

		.button-icon {
			width: 16px;
			height: 16px;
			background: url(../../assets/images/icons/arrow_blue.svg) no-repeat;
			background-position: center;
			transition: all 0.3s;

			&.open {
				rotate: 180deg;
				transition: all 0.3s;
			}
		}
	}

	.options_container {
		position: absolute;
		z-index: 9999;
		padding: 10px 5px;
		margin-top: 5px;
		border-radius: 6px;
		background-color: #ffffff;
		box-shadow: 0px 4px 6px 0px #0000001A;
		gap: 5px;
		display: flex;
		flex-direction: column;
		flex-wrap: nowrap;
		transition: all 0.3s;

		.option_select_style {
			cursor: pointer;
			padding: 10px 5px;
			font-size: 11px;
			font-weight: 400;
			line-height: 13.2px;
			color: #0D4B93;
			border-radius: 6px;
			word-break: keep-all;
			cursor: pointer;
			transition: all 0.3s;
			font-family: $menu;

			@include tablet-medium {
				font-size: 17px;
			}

			&:hover {
				background-color: #E3F3FD;
				transition: all 0.3s;
				border-radius: 3px;
				font-weight: 900;
			}
		}
	}
}