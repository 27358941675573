@import "../../../assets/scss/_fonts.scss";
@import "../../../assets/scss/_variables.scss";
@import "../../../assets/scss/_mixins.scss";

.signin-card {
	margin-bottom: 15px;
	padding: 40px;
	padding-bottom: 0rem;
	padding-top: 32px;
	max-width: 400px;
	text-align: center;
	border-radius: 2rem !important;
	border: none !important;

	@include phone-large {
		max-width: 100%;
		margin-left: auto;
		margin-right: auto;
		padding: 20px;
	}

	h1 {
		@include title;
		color: $brandDeepblue;
		text-align: center;
		font-size: 2rem !important;

		@include phone-large {
			font-size: 2.4rem !important;
		}
	}

	a {
		display: block;

		&.forget-password {
			width: 100%;
			text-align: center;
			padding-top: 20px;
			padding-bottom: 20px;
		}
	}

	.form-group {
		text-align: left;
		position: relative;
	}

	button {
		margin-top: 35px;
	}
	.success-text{
		font-family: $title;
		font-weight: bold;
		color: $brandDeepblue;
		min-height: 100px; 
	}
	
}

