@import "../../assets/scss/_fonts.scss";
@import "../../assets/scss/_variables.scss";
@import "../../assets/scss/_mixins.scss";

.button-app {
	@include buttonApp;
	position: relative;
	width: 100%;
	max-width: 270px;
	text-decoration: none !important;
	text-align: center;
	transition: all 300ms ease-in-out;

	@include tablet-small {
		border-radius: 12px;
	}

	&.secondary {
		@include buttonApp;
		background-color: $brandWhite;
		border: 2px solid $brandLightGrey;
		color: $brandMediumGrey;

		&:hover {
			background-color: $brandWhite;
			border: 1px solid $brandOrange;
			color: $brandDarkOrange;
		}
	}

	&.tertiary {
		@include buttonApp;
		background-color: $brandDarkishblue;
		color: $brandWhite;

		&:hover {
			background-color: darken($brandDarkishblue, 12%);
		}
	}

	.button-label {
		@include phone-large {
			font-size: 1.2rem;
		}
	}

	.button-icon {
		margin-left: 20px;
		position: absolute;
		right: 3%;
		top: 12px;
		transition: transform 300ms ease-in-out;

		&.signout-white {
			background: url(/assets/images/icons/sign-out-w.svg) no-repeat;
			width: 23px;
			height: 23px;
			display: inline-block;
		}

		&.download-white {
			background: url(/assets/images/icons/download-w.svg) no-repeat;
			width: 23px;
			height: 23px;
			display: inline-block;

			@include phone-large {
				right: 3px;
				top: 4px;
			}
		}

		&.eye-white {
			background: url(/assets/images/icons/eye-w.svg) no-repeat;
			width: 23px;
			height: 23px;
			display: none;

			@include desktop-large {
				display: none;
			}

			@include desktop-medium {
				display: none;
			}

			@include phone-large {
				display: inline-block;
				right: 4px;
				top: 6px;
			}
		}

		&.btn-copy {
			background: url(/assets/images/icons/iconCopy.svg) no-repeat;
			width: 23px;
			height: 23px;
			display: inline-block;
		}

		&.videocall-white {
			background: url(/assets/images/icons/videocall-w.svg) no-repeat;
			width: 23px;
			height: 23px;
			display: inline-block;
		}

		&.upload-file-white {
			background: url(/assets/images/icons/upload-file2-w.svg) no-repeat;
			width: 23px;
			height: 23px;
			display: inline-block;
		}

		&.arrow-right-white {
			background: url(/assets/images/icons/arrow-w.svg) no-repeat;
			width: 23px;
			height: 23px;
			display: inline-block;
		}

		&.arrow-left-grey {
			background: url(/assets/images/icons/arrow-left-g.svg) no-repeat;
			width: 23px;
			height: 23px;
			display: inline-block;
			left: 0px;
			top: 25%;
			margin-left: 0px;
		}
	}

	&:hover {
		background-color: $brandDarkOrange;
		cursor: pointer;
	}

	&.icon-button {
		width: 46px;
		height: 45px;

		.edit {
			background: url(/assets/images/icons/edit-w.svg) no-repeat;
			width: 23px;
			height: 23px;
			display: inline-block;
			position: static;
			margin: 0px;
		}
	}
}

.small-button {
	font-size: 1rem;
	padding: 5px 15px;
	border-radius: 10px;
	display: flex;
	justify-content: center;
	align-items: center;
	max-width: max-content;

	@include tablet-small {
		padding: 5px 10px;
	}

	.button-label {
		@include phone-large {
			display: none;
		}
	}

	& .button-icon {
		position: static;
		margin-left: 10px;

		@include phone-large {
			margin-left: 0px;
		}
	}
}