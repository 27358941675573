@import "../../../assets/scss/_variables.scss";
@import "../../../assets//scss/_mixins.scss";

.container {
	@include tablet-small {
		margin-right: 0px;
	}
}

.list-item {
	display: block;
	padding: 20px;
	border-radius: 10px;
	border-bottom-left-radius: 0px;
	border-bottom-right-radius: 0px;
	width: 100%;
	text-align: center;
	background-size: 30px;
	background-color: $brandLightGrey;
	font-size: 1rem;

	&:active {
		background-color: $brandDeepblue;
	}

	@include desktop-large {
		font-size: 1rem;
	}

	@include tablet-medium {
		padding: 20px;
	}

	@include tablet-small {
		width: 100%;
	}

	span {
		@include tablet-small {
			display: none;
		}
	}

	&:hover {
		text-decoration: none;
		background-color: $brandDeepblue;
		color: $brandWhite;
		cursor: pointer;
	}

	&.list-active {
    background-color: $brandDeepblue !important;
    color: $brandWhite; 

		@include tablet-small {
			// background: url(../../../../assets/images/icons/note-w.svg) no-repeat center $brandDeepblue;
			background: $brandDeepblue;
			background-size: 28px;
		}
	}

}

.plan-amigo {
	.list-item {
		// width: 190px;

		&.plan {
			@include tablet-large {
				background: url(../../../assets/images/icons/eye-b.svg) no-repeat center $brandLightGrey;
				background-size: 30px;

				&.list-active {
					background: url(../../../assets/images/icons/eye-w.svg) no-repeat center;
					background-size: 30px;
				}
			}
		}

		&.recomendar {
			@include tablet-large {
				background: url(../../../assets/images/icons/speaker-b.svg) no-repeat center $brandLightGrey;
				background-size: 26px;

				&.list-active {
					background: url(../../../assets/images/icons/speaker-w.svg) no-repeat center;
					background-size: 26px;
				}
			}
		}

		&.premios {
			@include tablet-large {
				background: url(../../../assets/images/icons/gift-tab-b.svg) no-repeat center
					$brandLightGrey;
				background-size: 25px;

				&.list-active {
					background: url(../../../assets/images/icons/gift-tab-w.svg) no-repeat center;
					background-size: 25px;
				}
			}
		}

		span {
			@include tablet-large {
				display: none;
			}
		}
	}
}

.list-actives {
	background-color: $brandDeepblue;
	color: $brandWhite;
}
