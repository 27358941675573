@import "../../assets/scss/_fonts.scss"; 
@import "../../assets/scss/_variables.scss"; 
@import "../../assets/scss/_mixins.scss"; 

.link-button-app {
    background-color: $brandOrange;
    border: none;
    font-size: 1.1rem;
    font-family: $menu;
    border-radius: $borderRadius;
    color: $brandWhite;
    padding: 10px 15px;
    line-height: 1.8rem;
    transition: all 300ms ease-in-out;
    text-decoration: none !important;
    display: flex;
    justify-content: space-around;
    align-items: center;
    width: max-content;

    &:hover {
      background-color: $brandDarkOrange;
    }

    @include phone-large {
        height: 66px;
    }

    @include phone-small {
        height: 50px;
    }

    .link-button-icon {
        margin-left: 12px;
        
        @include tablet-small {
            display: none;
        }

        &.arrow-right-white {
			background: url(/assets/images/icons/arrow-w.svg) no-repeat;
			width: 23px;
			height: 23px;
			display: inline-block;
		}
    }

    &:hover {
        @include tablet-small {
            color: $brandOrange;
        }
    }

    &.upload-file {
        @include tablet-small {
            position: absolute;
            width: 100%;
            max-width: 95%;
            left: 0;
            top: 0;
            height: 72px;
            background: url(../../assets/images/icons/arrow-right-o.svg) no-repeat transparent;
            background-position: right center;
            background-size: 25px;
        }
    }
}