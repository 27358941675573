@import "../../../../../assets/scss/_fonts.scss";
@import "../../../../../assets/scss/_variables.scss";
@import "../../../../../assets/scss/_mixins.scss";

.list-item-container {
	padding: 30px;
	border-radius: 15px;
	border: 1px solid lighten( #000000, 90%);	
	display: grid;
	grid-template-columns: 150px 500px auto;
	grid-template-rows: repeat(2, 1fr);

	@include desktop-large {
		grid-template-columns: 105px auto;
	}

	@include tablet-large {
		grid-template-columns: 95px auto;
		grid-template-rows: 85px auto;
	}

	@include phone-large {
		grid-template-columns: 85px auto;
		grid-template-rows: 65px auto;
		padding: 20px;
	}

	.list-item-title {
		grid-column: 2 / 4;
		display: flex;
    align-items: center;
	}

	h3 {
		@include title;
		color: $brandDeepblue;
		font-size: 2rem;
		margin: 0px;
	}

	.list-item-info {
		display: flex;
		justify-content: space-between;
		grid-column: 2 / 4;

		@include tablet-large {
			grid-column: 1 / 4;
			flex-direction: column;
			padding-top: 15px;
		}

		p {
			max-width: 75%;
			margin: 0px;
			font-size: 1rem;

			@include tablet-large {
				max-width: 100%;
			}

			@include phone-large {
				font-size: 1.1rem;
			}
		}
		
		.link-button-app {
			flex-shrink: 0;
			height: max-content;
			justify-content: space-between;

			@include tablet-large {
				margin-top: 20px;
			}

			@include tablet-small {
				width: 100%;
			}
		}
	}

	.list-item-icon {
		max-width: 110px;
		margin-right: 20px;
		grid-row: 1 / 3;
		display: flex;
		align-items: center;

		@include tablet-large {
			grid-row: 1;
		}
	}
}
