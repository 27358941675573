* {
    box-sizing: border-box;
}

html {
    font-size: 16px;
}

body {
    margin: 0;
}

ul {
    list-style-type: none;
    padding: 0px;
    margin: 0px;
}

a {
    color: inherit;
    text-decoration: none;
}

img {
    width: 100%;
}

h1,
h2,
h3,
h4,
h5 {
    margin: 0px;
}
