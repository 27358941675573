@import "../../../assets/scss/fonts";
@import "../../../assets/scss/variables";
@import "../../../assets/scss/mixins";

.drag-drop-container {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;

	@include tablet-small {
		padding: 10px;
		text-align: center;
	}
}

.file-uploaded {
	border-top: 1px solid $brandLightGrey;
	border-bottom: 1px solid $brandLightGrey;
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding-top: 15px;
	padding-bottom: 15px;

	@include tablet-small {
	}

	p {
		margin: 0px;
	}

	img {
		max-width: 30px;
		@include tablet-small {
			display: none;
		}
	}
}

.upload-file {
	display: flex;
	flex-direction: column;
	align-items: center;
	margin-bottom: 3%;

	@include tablet-large {
		margin-bottom: 5%;
	}

	@include phone-large {
		margin-bottom: $separation;
	}
}

.modal-file-icon {
	width: 100px;
	margin: auto;
}

.modal-file-text {
	font-weight: 700;
	font-family: $title;
	color: $brandDarkishblue;
}

.files-list-container {
	width: 80%;
	.report-content {
		display: flex;
		width: 100%;
		border-top: 1px solid #e2e2e2;

		@include tablet-large {
			padding-top: 20px;
			padding-bottom: 20px;
		}

		.main-icon {
			width: 85px;
			margin-right: 20px;

			@include tablet-large {
				width: 65px;
			}

			@include tablet-small {
				width: 60px;
			}

			@include phone-large {
				width: 35px;
			}
		}

		.left {
			width: 100%;
			display: flex;
			justify-content: left;
			align-items: center;
		}

		.top {
			width: 100%;
		}

		
		.bottom {
			width: 160px;
			
			@include desktop-large {
				width: auto;
			}
		}
		.no-width{
			width: auto !important;
		}
	}
}
