@import "../../../assets/scss/_fonts.scss";
@import "../../../assets/scss/_variables.scss";
@import "../../../assets/scss/_mixins.scss";


.subtitle {
  margin-bottom: 20px;
  text-align: center;
}

.modal-poll-container {
  max-width: 400px;

  h1 {
    position: static !important;
    font-size: 1.8rem !important;
    margin-bottom: 12%;
  }
}

.client-poll {
  display: flex;
  max-width: 800px;
  margin: auto;
  display: flex;
  justify-content: space-between;

  @include tablet-medium {
    flex-direction: column;
  }


  button.card-poll{
    border: 1px solid $brandLightGrey;
    max-width: 100%;
    border-radius: 1em;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 6%;
    background: transparent;
    cursor: pointer;

    @include tablet-medium {
      max-height: 190px;
      padding: 2%;
      margin: 0px 0px 20px 0px;
    }

    @include phone-large {
      margin: 0px 0px 10px 0px;
    }

		&:focus {
			outline: 0px;
	 	}

    &:hover {
      background-color: $brandLightBlue;
      border-color: $brandWhite;
    }

    img {
      width: 125px;
      display: inline-block;
      margin-bottom: 20px;

      @include tablet-medium {
        width: 100px;
        height: 100px;
      }

      @include phone-large {
        width: 65px;
        height: 65px;
        margin-bottom: 12px;
      }

      @include phone-small {
        width: 45px;
        height: 45px;
      }
    }

    p {
      font-family: $title;
      text-transform: uppercase;
      color: $brandDeepblue;
      font-weight: 500;

      @include tablet-medium {
        margin: 0px;
      }
    }
  }
}


// @media(max-width: 768px){
//   .client-poll{
//     flex-direction: column;

//   }


// }