@import "../../../assets/scss/_fonts.scss"; 
@import "../../../assets/scss/_variables.scss"; 
@import "../../../assets/scss/_mixins.scss";


.select-container {
  padding: 7px 7px 7px 0px;
  display: flex;
  flex-direction: column;
  max-width: 250px;

  label {
    font-family: $text;
    font-weight: 400 !important;
    color: $brandMediumGrey;
    padding-bottom: 10px;
  }

  .select-element {
    border-radius: 12px;
    border: 1px solid $brandBorderGrey;
    text-align: left;
    padding: 10px;
    margin-bottom: 20px;

    &:focus {
      outline: 1px solid $brandDeepblue;
    }

    select {
      border: none;
      width: 100%;
      background-color: transparent;

      &:focus {
        outline: none;
      }
    }


  }
}