@import "../../../assets/scss/_fonts.scss";
@import "../../../assets/scss/_variables.scss";
@import "../../../assets/scss/_mixins.scss";

.logo-container {
	height: 100px;
	width: 100%;
	position: absolute;
	top: 0;

	@include desktop-small {
		position: static;
	}
}

.setpassword-card {
	margin-bottom: 15px;
	padding-top: 10px;
	text-align: center;
	border: none;
	border-radius: 2rem;

	@include desktop-medium {
		max-width: 90vw !important;
	}

	@include tablet-large-portrait {
		max-width: 35rem !important;
	}
	
	.flex-container {
		@include desktop-medium {
			display: flex;
			flex-direction: row;
			position: relative;
		}

		@include tablet-large-portrait {
			display: block;
		}

		@include tablet-small {
			display: block;
		}

		.left {
			@include desktop-medium {
				max-width: 56%;
			}

			@include tablet-large-portrait {
				max-width: 100%;
			}
		}
	}

	// @include desktop-medium {
	// 	flex-direction: row;
	// 	max-width: 90vw !important;
	// }

	// .top-content {
	// 	@include desktop-small {
	// 		margin-bottom: 5px;
	// 	}
	// }

	.checkboxes {
		padding-top: 20px;
		padding-left: 8%;
		padding-bottom: 8%;

		@include desktop-medium {
			padding-left: 0px;
		}
	}

	h1 {
		@include title;
		color: $brandDeepblue;
		font-size: 2rem !important;
		margin-bottom: 45px;
	}
    
	.form-group{
		max-width: 350px;
		margin: auto;
	}

	button {
		margin-top: 20px;

		@include desktop-small {
			margin-bottom: 20px;
		}
	}

	.button-app {
		@include desktop-medium {
			transform: translateX(-70%);
			margin-bottom: 20px;
		}

		@include tablet-large-portrait {
			transform: initial;
		}
	}

	.setPassword-requirements {
		border: 1px solid $brandLightBlue;
		border-radius: $borderRadius;
		position: relative;
		padding: 5%;
		padding-top: 10%;
		max-width: 70%;
		margin-left: auto;
		margin-right: auto;

		@include desktop-small {
			padding-top: 7%;
		}

		@include phone-large {
			max-width: 100%;
		}

		.requirements-list {
			li {
				&:before {
					content: '-';
				}
			}
		}


		.warning-icon {
			position: absolute;
			margin: auto;
			left: 0;
			right: 0;
			top:-15%;
			display: block;
			max-width: 80px;

			@include desktop-small {
				max-width: 60px;
			}

			@include phone-large {
				max-width: 50px;
				top: -30px;
			}
		}

		.heavy-text {
			color: $brandDeepblue;
			font-weight: 500;
			font-family: $title;
		}
  }

	label.legal-checkbox {
		font-weight: normal;
		font-family: $text;
		font-size: 0.8rem;
		display: flex;
		justify-content: flex-start;
		align-items: center;
		padding: 0px;
		margin: 0px;

		&>input {
			width: 25px;
		}

		span {
			margin: 0px;
		}

		a {
			font-size: 0.8rem;
			text-decoration: underline;
		}
	}
}

