@import "../../../assets/scss/_fonts.scss";
@import "../../../assets/scss/_variables.scss";
@import "../../../assets/scss/_mixins";

.videocall {
  height: 100vh;
  
  .logo-container {
    position: static;
    margin-bottom: 8vh;

    .logo {
      max-width: 300px;
    display: block;
    padding-left: 20px;
    padding-top: 20px;
    }
  }

  iframe {
    width: 100%;
  }

  .main-content {
    margin: auto;
    max-width: 55vw !important;

    @include desktop-large {
      max-width: 80vw !important;
    }

    @include tablet-small {
      max-width: 95% !important;
    }

    h1 {
      font-family: $title;
      color: $brandDeepblue;
      font-size: 1.9rem;
      position: absolute;
      top: -30px;
      left: 35px;
    }

    .card{
      border-radius: 2rem;
      padding: 3.25rem;
      max-width: 100%;
      background-color: #fff;
      margin: auto;
      text-align: left;
      border: none;

      @include tablet-small {
        padding: 12px;
      }
    }
  }
}