@import "/../../../assets/scss/fonts.scss";
@import "/../../../assets/scss/_variables.scss";
@import "/../../../assets/scss/_mixins.scss";

.subtitle {
  margin-bottom: 20px;
}

.cards-container {
  .card {
    @include tablet-small {
      position: relative;
      max-width: 95% !important;
      justify-content: flex-start;
    }

    h3 {
      @include tablet-small {
        margin: auto;
        transform: translate(-10px);
      }

      @include phone-large {
        font-weight: 500;
      }
    }

    .button-app {
      @include tablet-small {
        position: absolute;
        width: 100%;
        max-width: 95%;
        left: 0;
        top: 0;
        height: 72px;
        background: url(../../../assets/images/icons/arrow-right-o.svg) no-repeat transparent;
        background-position: right center;
        background-size: 25px;
      }

      @include phone-large {
        height: 66px;
      }

      @include phone-small {
        height: 50px;
      }

      .button-icon {
        @include tablet-small {
          display: none;
        }
      }

      &:hover {
        @include tablet-small {
          color: $brandOrange;
        }
      }
    }
  }
}

.color-icon {
  width: 100%;
  max-width: 130px;
  margin-left: auto;
  margin-right: auto;

  @include desktop-large {
    max-width: 80px;
  }

  @include tablet-small {
    width: 50px;
    height: 55px;
    margin: 0px;
  }

  @include phone-large {
    width: 40px;
    height: 40px;
  }

  @include phone-large {
    width: 35px;
    height: 35px;
  }
}

.top-alert-content {
  display: flex;
  align-items: center;
  justify-content: center;

  h3 {
    margin-bottom: 0px !important;
    margin-left: 20px;
  }

  img {
    max-width: 70px;
  }
}

.bottom-alert-content {
  h4 {
    font-family: $title;
    color: $brandDeepblue;
    font-size: 1.2rem;
  }
}

.clinic_list_modal_container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0px 10px;
  max-width: 580px;

  @include phone-large {
    max-width: 400px;
    padding: 0px 0px;
  }

  h2 {
    font-size: 28px;
    font-weight: 700;
    line-height: 34.66px;
    text-align: center;
    font-family: $title;
    color: $brandDeepblue;
    margin-bottom: 0px;

    @include phone-large {
      font-size: 22px;
      line-height: 27.24px;
    }
  }

  p {
    font-family: $text;
    font-weight: 400;
    text-align: center;
    color: $brandMediumGrey;
    font-size: 17px;
    line-height: 19.72px;
    margin-bottom: 0px;

    @include phone-large {
      font-size: 15px;
      line-height: 20px;
    }
  }

  .list_btn_container {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
    gap: 20px;
    margin-top: 25px;

    .button_clinic_selector {
      height: 52px;
      max-width: 350px;
      width: 100%;
      border-radius: 10px;
      border: 1px solid #DADADA;
      padding: 10px 24px 10px 36px;
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      justify-content: space-between;
      align-items: center;
      background-color: #FFFFFF;
      cursor: pointer;
      transition: all 0.3s;

      @include phone-large {
        padding: 10px 16px 10px 24px;
      }

      @include phone-small {
        padding: 10px 10px 10px 15px;
      }

      &:hover {
        border-radius: 5px;
        border: 1px solid $brandDarkOrange;
        transition: all 0.3s;
      }

      .button-label {
        font-family: $text;
        font-size: 16px;
        font-weight: 700;
        line-height: 19.81px;
        text-align: center;
        color: #0D4B93;
        text-transform: capitalize;

        @include phone-large {
          font-size: 14px;
        }

        @include phone-small {
          font-size: 12px;
        }
      }

      .button-icon {
        width: 16px;
        height: 16px;
        background: url(../../../assets/images/icons/arrow-right-o.svg) no-repeat;
        background-position: center;
      }
    }
  }
}

.clinic_load_data_modal_container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0px 10px;
  max-width: 580px;

  @include phone-large {
    max-width: 400px;
    padding: 0px 0px;
  }

  .loading {
    min-height: 120px;
  }
}