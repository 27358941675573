@import "../../../../../assets/scss/_fonts.scss";
@import "../../../../../assets/scss/_variables.scss";
@import "../../../../../assets/scss/_mixins";

.radio-group {
  margin-bottom: 20px;
  
  .radio-label {
    display: flex;
    cursor: pointer;
  }
}
