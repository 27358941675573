@import "../../assets/scss/fonts.scss";
@import "../../assets/scss/_variables.scss";
@import "../../assets/scss/_mixins.scss";

.update-info {
  position: absolute;
  top: 0px;
  right: 0px;
  display: flex;
  align-items: center;

  @include phone-large {
    top: 0px !important;
    right: 10px !important;
  }

  @include desktop-small {
    font-size: 1rem;
  }
}

  .updating-text {
    color: $brandOrange;

    .updating {
      background: url(../../assets/images/icons/updating-o.svg) no-repeat;
      width: 20px;
      display: inline-block;
      height: 20px;
      margin-right: 5px;
      -webkit-animation:spin 1s linear infinite;
      -moz-animation:spin 1s linear infinite;
      animation:spin 1s linear infinite;
    }
  }

  .updated-text {
    color: $brandCorrect;

    .updated {
      background: url(../../assets/images/icons/correct-g.svg) no-repeat;
      width: 20px;
      display: inline-block;
      height: 20px;
      margin-right: 5px;
    }
  }


@-moz-keyframes spin {
  100% {
    -moz-transform: rotate(-360deg);
  }
}

@-webkit-keyframes spin {
  100% {
    -webkit-transform: rotate(-360deg);
  }
}

@keyframes spin {
  100% {
    -webkit-transform: rotate(-360deg);
    transform: rotate(-360deg);
  }
}