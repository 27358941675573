@import "../../assets/scss/fonts.scss";
@import "../../assets/scss/_variables.scss";
@import "../../assets/scss/_mixins.scss";

.time-box {
	cursor: pointer;
	border: 1px solid $brandOrange;
	width: 80px;
	border-radius: 15px;
	margin-left: 5px;
	margin-right: 5px;
	display: flex;
	flex-direction: column;
	padding-top: 4px;
	padding-bottom: 4px;
	font-family: $title;
	color: $brandDeepblue;

	&:hover {
		color: $brandOrange;
	}
}


.time-selected, .time-selected:hover{
  background-color: $brandOrange !important;

  p {
    color: $brandWhite;
  }
}