@import "../../../assets/scss/_fonts.scss";
@import "../../../assets/scss/_variables.scss";
@import "../../../assets/scss/_mixins.scss";

.privacy-container {
	display: flex;
	flex-direction: column;
	width: 60%;
	margin: auto;
	justify-content: center;
	.text-container {
		text-align: center;
	}

	.legal-checkbox {
		font-family: $text;
		font-size: 0.9rem;
		color: $brandMediumGrey;
		display: flex;
		justify-content: center;
		align-items: baseline;

		input {
			max-width: 30px;
		}
	}

	.accept-button {
    margin-top: 20px;
		display: flex;
		justify-content: center;
	}
}

.accept-button {
	margin-top: 20px;
	display: flex;
	justify-content: center;
}

.plan-amigo {
	@include phone-large {
		width: 95%;
		margin: auto;
	}

	.discover-page {
		margin-top: -65px;

		@include tablet-large {
			margin-top: -75px;
		}
	}

	.recomend-page {
		text-align: center;

		img {
			max-width: 500px;
			margin: 80px 0px 50px 0px;
		}

		h3 {
			@include title;
			font-size: 1.3rem;
			color: $brandDeepblue;
		}
	}

	.hotels-page {
		.prize-item-container {
			min-height: 390px;

			.info-container {
				top: 0px;
			}
		}
	}

	.update-info {
		display: none;
	}

	.modal-container {
		button {
			max-width: 200px;
			margin-bottom: 10px;
		}

		.flex-container {
			display: flex;
			justify-content: space-evenly;

			@include tablet-small {
				flex-direction: column;
				align-items: center;
			}
		}

		.modal-content {
			.success-text {
			}

			.modal-icon {
				max-width: 75px;
				margin: auto;
			}

			.modal-exchange-description {
				max-width: 500px;
				margin: auto;
				margin-bottom: 20px;
			}

			h4 {
				@include title;
				color: $brandDeepblue;
				font-size: 1.8rem;
				margin: 20px;
			}
		}
	}

	.plan-image {
		max-width: 400px !important;
		margin: auto;
		display: block;
		padding-bottom: 8%;
	}

	.block-top {
		display: flex;
		margin: 20px 0px;
		font-size: 1rem;

		@include tablet-large {
			flex-direction: column-reverse;
			margin-top: 15px;
		}

		@include tablet-medium {
			font-size: 1.1rem;
		}

		.col-left {
			display: flex;
			flex-direction: column;
			width: 70%;

			p {
				margin-left: 70px;
				margin-top: 0px;

				@include tablet-small {
					margin-left: 0px;
					margin-top: 20px;
				}
			}

			@include tablet-large {
				width: 100%;
				margin-top: -50px;
			}

			@include tablet-small {
				margin-top: 0px;
			}

			.block-top-icon {
				margin-right: 20px;
				flex-shrink: 0;
				display: flex;
				align-items: center;

				h2 {
					@include title;
					color: $brandDeepblue;
					font-size: 2.25rem;
					margin-left: 10px;
				}

				img {
					width: 60px;

					@include tablet-small {
						width: 45px;
					}
				}
			}

			.block-top-info {
				ul {
					list-style-type: disc;
					margin-top: 5px;
					padding-left: 20px;
				}
			}
		}

		.col-right {
			width: 30%;
			text-align: right;
			flex: auto;

			@include tablet-large {
				align-self: flex-end;
				width: 100%;
			}
		}
	}
}

.main-cta{
	position: absolute;
	width: 100%;
	margin: -5%;
	transform: translateY(150px);
	padding-bottom: 100px;

	@include desktop-large {
		transform: translateY(100px);
	}
	.cta {
		position: relative;
		min-width: 100%;
		border-radius: 30px;
		border-radius: 2rem;
		padding: 20px;
		background-color: white;
		text-align: left;
		.info{display: flex; align-content: center; justify-content: center;
			@include tablet-medium {
				flex-direction: column;
			}
			p, a{
				display: flex;
				align-items: center;
				justify-content: center;

				&::before{
    			content: "";
    			display: block;
    			
    			height: 25px;
    			background-repeat: no-repeat;
    		
				}			
					
			}

			.email{
				border-right: 2px solid black; height: 20px; padding-right: 15px; margin-right: 15px;
				@include tablet-medium {
					border-right: 0px;
				}
				&::before{
					width: 25px;
					background-image: url('../../../assets/images/icons/mail.png');
				}
			}
			
			.telefono{
				&::before{
					width: 50px;
					background-image: url('../../../assets/images/icons/phone_whp.png');
				}
			}
			
		}
	}
}
