@import "../../assets/scss/_fonts.scss";
@import "../../assets/scss/_variables.scss";
@import "../../assets/scss/_mixins.scss";
.top-content {
    margin-bottom: 20px;
    margin-left: -10px;
    .back {
        display: inline-block;
        color: $brandMediumGrey;
        background-color: transparent;
        text-align: left;
        border: none;
        cursor: pointer;
        font-family: $text;
        font-size: 1.1rem;
        display: flex;
        align-items: center;
        max-width: 100px;
//        transition: color 300ms ease-in-out;
        &:before {
            content: "";
            display: inline-block;
            background: url(../../assets/images/icons/arrow-left-g.svg) no-repeat;
            width: 22px;
            height: 28px;
            margin-right: 8px;
            @include phone-large {
                width: 20px;
                height: 24px;
                margin-right: 0px;
            }
        }
        &:hover:before {
            background: url(../../assets/images/icons/arrow-left-o.svg) no-repeat;
        }
        .button-label {
            margin-left: 20px;
        }
        &:hover {
            background-color: transparent;
            color: $brandOrange;
        }
        &:focus {
            outline: 0px;
        }
    }
}

.forgot-card,
.setpassword-card {
    .top-content {
        height: 30px;
        position: relative;
    }
    label {
        text-align: left;
    }
}