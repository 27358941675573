@import "../../../assets/scss/_fonts.scss";
@import "../../../assets/scss/_variables.scss";
@import "../../../assets/scss/_mixins.scss";


.menu-item {
	margin-top: 35px;

	&:first-child {
		margin-top: 0px;
	}

	&.selected {
		.link-icon {
			background-color: $brandWhite !important;
		}
	}

	.menu-link {
		color: $brandWhite;
		font-size: 0.8rem;
		font-family: $text;
		text-decoration: none !important;
		@include flexColumnCenter;
		cursor: pointer;

		.link-icon {
			border-radius: 100%;
			font-size: 24px;
			height: 60px;
			width: 60px;
			margin-bottom: 6px;

			@include tablet-small {
				width: 50px;
				height: 50px;
			}

			@include phone-small {
				width: 40px;
				height: 40px;
			}
		}

		&>span {
			@include tablet-medium {
				display: none;
			}
		}
	}
}

//MENU ICONS VARIATIONS

.menu-item {
	.menu-link {
		.link-icon {
			@include iconsBackground;

			@include phone-large {
				background-size: 22px !important;
			}
			
			&.account {
				background-image: url('../../../assets/images/icons/user-w.svg');
			}
			&.reports {
				background: url('../../../assets/images/icons/reports-w.svg');
			}
			&.calendar {
				background: url('../../../assets/images/icons/calendar-w.svg');
			}
			&.contact {
				background: url('../../../assets/images/icons/contact-w.svg');
			}
			&.friend {
				background: url('../../../assets/images/icons/friend-w.svg');
			}
		}
  }
    &:hover, &.selected {

        .link-icon {
						background-color: $brandWhite !important;

            &.account {
                background: url('../../../assets/images/icons/user-b.svg');
            }
            &.reports {
                background: url('../../../assets/images/icons/reports-b.svg');
                
            }
            &.calendar {
                background: url('../../../assets/images/icons/calendar-b.svg');
            }
            &.contact {
                background: url('../../../assets/images/icons/contact-b.svg');
            }
            &.friend {
                background: url('../../../assets/images/icons/friend-b.svg');
            }
        }
		}

		&:hover:not(.selected) {
			@include tablet-large {
				.link-icon {
					background-color: $brandHoverBlue !important;

					&.account {
						background: url('../../../assets/images/icons/user-w.svg');
					}
					&.reports {
						background: url('../../../assets/images/icons/reports-w.svg');
					}
					&.calendar {
						background: url('../../../assets/images/icons/calendar-w.svg');
					}
					&.contact {
						background: url('../../../assets/images/icons/contact-w.svg');
					}
					&.friend {
						background: url('../../../assets/images/icons/friend-w.svg');
					}
				}
			}
		}
}





