@import "../../../assets/scss/_fonts.scss"; 
@import "../../../assets/scss/_variables.scss"; 
@import "../../../assets/scss/_mixins.scss";

.prize-item-container{
    max-width: 32%;
    min-height: 480px;
    max-height: 480px;
    position: relative;
    border: 1px solid $brandLightGrey;
    border-radius: 20px;
    overflow: hidden;
    margin-bottom: 25px;

    &:hover {
        .spa-name, .spa-province {
            color: $brandOrange;
            transition: all 300ms ease-in-out;
        }
    }

    @include desktop-large {
        max-width: 48%;
    }

    @include tablet-large {
        min-height: 420px;
        max-height: 420px;
    }

    @include tablet-small {
        max-width: 100%;
        min-height: 100%;
        max-height: 100%;
        height: max-content;
    }

    a:hover {
        text-decoration: none;
    }
    .image-container{
    }

    .info-container{
        padding: 15px 20px;
        background-color:$brandWhite;
        z-index: 100;
        position: relative;
        top: -60px;

        @include tablet-small {
            top: 0px;
        }

        .spa-name {
            font-family: $title;
            margin-bottom: 0px;
            font-size: 1.4rem;
        }

        .spa-province {
            text-transform: uppercase;
            margin-top: 0px;
            font-size: 0.9rem;
        }

        .spa-description {
            color: $brandMediumGrey;
            font-size: 0.9rem;

            @include tablet-small {
                font-size: 1.1rem;
            }
        }
    }
}