@import "/../../../../assets/scss/fonts.scss";
@import "/../../../../assets/scss/_variables.scss";
@import "/../../../../assets/scss/_mixins.scss";

.invoices-container {
  .report-content {
    padding-top: 0px;
    
    &:first-child {
      border-top: 0px;
    }
  }
  .controls {
    position: relative;
    // margin-top: -40px;
    margin-bottom: 40px;

    @include phone-large {
      margin-bottom: 20px;
    }
  }
}

.left-column, .right-column {
  width: 58% !important;

  .column-content {
    border: 1px solid $brandLightGrey;
    border-radius: 2rem;
    padding: 2rem;

    @include phone-large {
      padding: 15px;
    }
  }
}

.column-top {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 20px;


  @include phone-large {
    margin-bottom: 10px;
  }

  h3{
    @include title;
    color: $brandDeepblue;
    font-size: 30px;
    margin-left: 20px;
    margin-bottom: 0px;

    @include tablet-large {
      font-size: 1.7rem;
    }

    @include phone-large {
      margin-left: 12px;
    }
  }

  img {
    max-width: 65px;

    @include tablet-large {
      max-width: 50px;
    }

    @include phone-large {
      max-width: 35px;
    }
  }
}
