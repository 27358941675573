@import "../../../../assets/scss/fonts.scss";
@import "../../../../assets/scss/_variables.scss";
@import "../../../../assets/scss/_mixins.scss";

.top-modal-info {
  h3 {
    margin-bottom: 0px !important;
  }
}

.textarea-container {
  padding-left: 10px;
  padding-right: 10px;
  margin-bottom: 20px;

  span {
    margin-bottom: 10px;

    &:first-child {
      transform: initial;
    }
  }
}

.days-container, .times-container {
    margin-left: 0px;
    display: flex;
    justify-content: center;
}

.legal-checkbox {
    font-family: $text;
    font-size: 0.9rem;
    color: $brandMediumGrey;
    display: flex;
    justify-content: center;
    align-items: baseline;
    margin-top: 40px;

    input {
        max-width: 30px;
    }
}

.button-app.top-space-large {
  @include desktop-small {
    margin-top: 10px;
  }
}

.disabled-button{
    background-color: $brandMediumGrey !important; 
    cursor: default !important;
}


.overlay {
  .modal-container {
    .modal-content {
      h3.message {
        font-size: 1.1rem !important;
        margin: 0 auto !important;
        margin-top: 20px;
      }
      .textarea-container{
        label{
          span{
            font-size: 1rem;
          }
        }
      }
    }
  } 

  .no-videocall-date {
    img,h3 {
      max-width: 400px;
    }
  }
}