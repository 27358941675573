@import "../../../../assets/scss/fonts";
@import "../../../../assets/scss/variables";
@import "../../../../assets/scss/mixins";

.success-text {
  font-family: $title;
  font-weight: bold;
  color: $brandDeepblue;
}

.card-top-title {
  justify-content: space-between;
  margin-bottom: 10px !important;
}

.social-network-container__icons {
  padding-left: 30px;
}

.plan-amigo {
  .block-top {
    .col-left {
      .block-top-icon {
        h2 {
          font-size: 1.7rem;
          padding: 10px 0px 0px 10px;
        }

        img {
          width: 70px;
        }
      }

      .block-top-info {
        p {
          padding: 0px 0px 0px 23px;
        }
      }
    }
  }
}

.recomend-container {
  .recomend-form {
    form {
      display: flex;
      position: relative;

      label {
        & > span {
          display: none;
        }

        max-width: 400px;
        min-width: 400px;

        input {
          background-image: url(../../../../assets/images/icons/email-b.svg);
          background-repeat: no-repeat;
          background-position: 97% center;
          background-size: 25px;
        }
      }

      .button-app {
        height: max-content;
        margin-left: 20px;
      }

      .form-error {
        position: absolute;
        bottom: -40px !important;
      }
    }

    .send-invitation {
      display: flex;
      margin-top: 20px;

      input[type="text"] {
        min-width: 500px;
      }
    }
  }

  .friend-list-container,
  .social-network-container {
    margin-top: 50px;
    padding: 20px;
    border-radius: 15px;
    border: 1px solid lighten(#000000, 90%);
    .social-network-container__icons {
      display: flex;
      .social-network-item {
        margin: 0 20px 0 0;
      }
    }
  }

  svg {
    width: 40px;
  }
}

.friend-list-containe {
  .card-top-title {
    .wrapper-icon {
      img {
        width: 30px !important;
      }
    }
  }
}

.form-actions {
  margin-top: 10px;
  display: flex;
  justify-content: space-around;
}

.form-field {
  margin-top: 10px;
}
.recomend-link {
  background-color: $brandLightGrey;
  padding: 30px;
  border-radius: 15px;
  overflow-x: scroll;
}

circle {
  fill: #62bfe8 !important ;
}

.social-network-container {
  .card-top-title {
    .wrapper-icon {
      img {
        width: 18px;
      }
    }
  }
}

.wrapper-icon {
  display: flex;
  align-items: center;
}
