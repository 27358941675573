@import "../../../../../assets/scss/_fonts.scss";
@import "../../../../../assets/scss/_variables.scss";
@import "../../../../../assets/scss/_mixins.scss";
.form-error {
    font-size: 0.8rem !important;
}

.change-password-account {
    margin-bottom: 15px;
    padding-top: 10px;
    text-align: center;
    h1 {
        position: static !important;
        font-size: 2rem !important;
        margin-bottom: 50px !important;
    }
    .flex-container {
        display: flex;
        flex-direction: row;
        position: relative;
        justify-content: space-between;
        @include tablet-large-portrait {
            display: block;
        }
        @include tablet-small {
            display: block;
        }
        .left {
            max-width: 56%;
            margin: auto;
            @include phone-large {
                max-width: 100%;
            }
        }
    }
    .top-content {
        margin-left: -18px;
    }
    .form-group {
        max-width: 350px;
        margin: auto;
        margin-bottom: 40px;
        @include tablet-large {
            margin-bottom: 20px;
        }
        span {
            text-align: left;
        }
    }
    button {
        margin-top: 20px;
    }
    .setPassword-requirements {
        border: 1px solid $brandLightBlue;
        border-radius: $borderRadius;
        position: relative;
        padding: 5%;
        margin-bottom: 30px;
        .requirements-list {
            li {
                @include tablet-large {
                    font-size: 1.1rem;
                }
                &:before {
                    content: '-';
                }
            }
        }
        .warning-icon {
            position: absolute;
            margin: auto;
            left: 0;
            right: 0;
            top: -20%;
            display: block;
            max-width: 70px;
            @include phone-large {
                max-width: 50px;
            }
        }
        .heavy-text {
            color: $brandDeepblue;
            font-weight: 500;
            font-family: $title;
        }
        .ninja{
            visibility: hidden;
            height: 2px;
        }
    }
    .button-app {
        @include desktop-medium {
            transform: translateX(-95%);
            margin-bottom: 20px;
        }
        @include tablet-large-portrait {
            transform: initial;
        }
        @include tablet-small {
            margin-bottom: 0px;
        }
    }
}

.overlay {
    .modal-container {
        padding-left: 30px;
        padding-right: 30px;
            overflow: auto;
    max-height: 100vh;
    }
}