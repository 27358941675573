@import "./assets/scss/_fonts.scss";
@import "./assets/scss/_reset.scss";
@import "./assets/scss/_variables.scss";
@import "./assets/scss/_mixins.scss";

html {
	font-size: 16px;
	overflow-x: hidden;

	@include desktop-medium {
		font-size: 14px;
	}

	@include tablet-small {
		font-size: 12px;
	}

	@include phone-large {
		font-size: 11px;
	}

	@include phone-small {
		font-size: 10px;
	}
}

body {
	color: $brandMediumGrey;
	background-image: url(/assets/images/app-bg-top.svg), url(/assets/images/app-bg-bottom.svg);
	background-position: center top, center bottom;
	background-repeat: no-repeat, no-repeat;
	background-color: $brandLightBlue;
	overflow-x: hidden;
	@include text;

	header {
		//		height: 220px;
	}

	a {
		color: $brandDarkishblue;

		&:hover {
			text-decoration: underline;
		}
	}

	.title-container {
		max-width: 500px;
		margin-left: auto;
		margin-right: auto;
		margin-bottom: 25px;


		p {
			@include desktop-small {
				font-size: 1.1rem;
			}
		}
	}

	.top-space {
		margin-top: $separation;

		&-large {
			margin-top: calc(20px * 2);
		}
	}

	.bottom-space {
		margin-bottom: $separation;
	}

	.main-content {
		max-width: $largeScreen;
		width: 100%;
		margin-left: auto;
		margin-right: auto;
		position: relative;

		@include desktop-large {
			max-width: 900px;
		}

		@include desktop-small {
			max-width: 800px;
		}

		@include tablet-large {
			max-width: 700px;
		}

		.subtitle {
			padding-top: 3%;

			@include desktop-large {
				font-size: 1.4rem;
			}

			@include phone-large {
				padding-top: 25px;
			}
		}
	}

	h1 {
		span {
			display: block;
		}
	}

	.content-top {
		display: flex;
	}

	.columns-container {
		display: flex;

		@include tablet-small {
			display: block;
		}

		@include phone-large {
			max-width: 92%;
			margin-left: auto;
			margin-right: auto;
		}

		.left-column,
		.right-column {
			width: 300px;

			@include tablet-small {
				width: 100% !important;
			}
		}

		.left-column {
			margin-right: 40px;
		}

		.right-column {
			@include tablet-small {
				margin-top: 20px;
			}
		}
	}

	.top-content {
		// margin-left: -18px;

		@include phone-large {
			margin-left: 0px;
			margin-bottom: 0px;
			margin-top: 12px;
		}
	}


	.empty-container {
		display: flex;
		justify-content: center;
		align-items: center;
		flex-direction: column;
		width: 70%;
		margin: auto;
		margin-top: 7%;
		max-width: 300px;

		img {
			width: 100%;
			max-width: 500px;
		}

		.no-content-info {
			@include title;
			color: $brandDeepblue;
			text-align: center;
			font-size: 1.2rem;
		}
	}

	.account-container {
		display: grid;
		grid-template-columns: 200px auto;
		height: 100vh;

		@include desktop-medium {
			grid-template-columns: 160px auto;
		}

		@include desktop-small {
			grid-template-columns: 135px auto;
		}

		@include tablet-medium {
			display: block;
		}

		.main-content {
			h1 {
				font-family: $title;
				font-weight: bold;
				font-size: 2.6rem;
				color: $brandDarkishblue;
				margin-bottom: 0px;
				position: relative;
				top: 12px;
				left: 35px;
				z-index: 10;

				@include desktop-large {
					font-size: 2.4rem;
				}

				@include tablet-small {
					left: 15px;
				}

				@include phone-small {
					top: 8px;
				}
			}

			.content-wrapper {
				border-radius: 2rem;
				padding: 5%;
				position: relative;
				background-color: $brandWhite;
				margin: auto;
				text-align: left;
				padding-top: 3%;
				// padding-bottom: 7%;
				margin-bottom: 7%;


				@include desktop-large {
					font-size: 1rem;
				}

				@include desktop-medium {
					font-size: 1.2rem;
				}

				@include tablet-small {
					padding: 6%;
					margin-bottom: 25%;
				}

				@include phone-large {
					padding: 10px;
					padding-bottom: 12%;
				}

				@include phone-small {
					padding: 5px;
					padding-bottom: 20px;
				}
			}
		}

		.page-content {
			grid-column-start: 2;
			grid-column-end: 13;
			overflow: scroll;

			@include tablet-large-portrait {
				display: flex;
				flex-direction: column;
				height: 100vh;
			}

			@include tablet-small {
				padding-left: 25px;
				padding-right: 25px;
			}

			@include phone-large {
				padding-left: 18px;
				padding-right: 18px;
			}

			@include phone-small {
				padding-left: 10px;
				padding-right: 10px;
			}

			.layout_select_clinic_style {
				display: none;

				@include tablet-medium {
					display: block;
				}
			}
		}
	}

	.signIn-container {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		height: 100vh;

		@include desktop-small {
			display: flex;
		}

		@include tablet-large-portrait {
			display: flex;
		}

		@include tablet-medium {
			display: flex;
		}

		@include phone-large {
			padding-left: 20px;
			padding-right: 20px;
			display: block;
		}

		@include phone-small {
			padding-left: 15px;
			padding-right: 15px;
		}


		.logo {
			width: 100%;
			max-width: 250px;
			margin: 40px;
			display: block;
			position: absolute;
			top: 0;
			left: 35px;

			@include desktop-small {
				margin-left: 0px;
				max-width: 210px;
			}

			@include tablet-large {
				margin-left: auto;
				margin-right: auto;
				// right: 0;
			}

			@include tablet-small {
				position: static;
			}
		}

		.main-content {
			text-align: center;

			// h1 {
			// 	@include title;
			// 	color: $brandDeepblue;
			// }
		}

		.form-group {
			margin-top: 20px;
		}

		a {
			font-size: 0.9rem;
		}
	}

	.button-app {
		&:focus {
			outline: 0px;
		}

		&:disabled {
			background-color: $brandMediumGrey;
			cursor: default;
		}
	}

	.radio-label {
		display: flex;
		cursor: pointer;

		&:hover {
			color: $brandOrange
		}
	}

	input[type=radio] {
		max-width: 40px;
		width: 40px;

		&:focus {
			outline: 0px;
			box-shadow: initial;
		}
	}

	.textarea-label {
		span:first-child {
			transform: initial;
		}
	}

	textarea {
		border-radius: 15px;
		border: none;
		background-color: #f2f2f2;
		line-height: 1.8rem;
		padding: 10px;
		width: 100%;
		margin-top: 0px;
		display: flex;
		justify-content: space-between;
		font-family: $text;

		span {
			transform: initial !important;
		}

		&:focus {
			outline: 0px;
			box-shadow: 0 0 0px 2px #7dbcea;
		}
	}

	.label-text {
		position: relative;
		color: $brandDeepblue;
		font-weight: 500;
		font-family: $title;
	}

	.request-report {
		display: flex;
		flex-direction: column;
		align-items: center;
		margin-bottom: 3%;

		@include tablet-large {
			margin-bottom: 5%;
		}

		@include phone-large {
			margin-bottom: $separation;
		}
	}

	.success-info-message {
		@include title;
		color: $brandDeepblue;
		font-weight: 500;
		font-size: 1.2rem;
	}

	.no-overflow {
		overflow-y: hidden;
		pointer-events: none;
		-webkit-overflow-scrolling: touch;

	}

	.card-top-title {
		display: flex;
		align-items: center;
		margin-bottom: 30px;

		h3 {
			margin: 0px;
		}

		img {
			width: 30px;
			margin-right: 10px;
		}
	}
}