@import "../../../assets/scss/_fonts.scss";
@import "../../../assets/scss/_variables.scss";
@import "../../../assets/scss/_mixins.scss";

.tab-content.reports {
  .report-content {
    display: flex;
    width: 100%;
    border-top: 1px solid #e2e2e2;

    @include tablet-large {
      padding-top: 20px;
      padding-bottom: 20px;
    }

    .main-icon {
      width: 85px;
      margin-right: 20px;

      @include tablet-large {
        width: 65px;
      }

      @include tablet-small {
        width: 60px;
      }

      @include phone-large {
        width: 35px;
      }
    }

    .left {
      width: 100%;
      display: flex;
      justify-content: left;
      align-items: center;
    }

    .top {
      width: 100%;
    }

    .bottom {
      width: 20%;

      @include desktop-large {
        width: auto;
      }
    }
  }
}