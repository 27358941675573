@import "../../../../assets/scss/_fonts.scss";
@import "../../../../assets/scss/_variables.scss";
@import "../../../../assets/scss/_mixins.scss";

.content-top {
  justify-content: space-between;
}
.readonly {
  border-radius: 15px;
  border: none;
  background-color: $brandLightGrey;
  line-height: 1.8rem;
  padding: 10px;
  width: 100%;
  margin-top: 0px;
  display: flex;
  justify-content: space-between;
  font-size: 1rem;
  max-height: 55px;

  @include desktop-large {
    padding: 6px;
  }

  span {
    line-height: 2.6rem;
  }
}
.label {
  margin-bottom: 5px;
  font-weight: 500;
  font-family: $title;
}
.input-icon {
  display: inline-block;
  width: 25px;
}

.personal-data {
  .update-info {
    // top: 25px;
    // right: 100px;
    top: 40px;
    right: 115px;

    @include desktop-small {
      right: 100px;
      top: 30px;
    }

    @include tablet-large {
      right: 100px;
    }

    @include phone-large {
      right: 70px !important;
      top: 25px !important;
    }
  }



  .left-column, .right-column {
    border: 0px;
  }

  .button-app.icon-button {
    @include phone-large {
      width: 40px;
      height: 40px;
      margin-top: 10px;
      margin-right: 10px;
    }

    .edit {
      @include phone-large {
        width: 20px;
        height: 20px;
      }
    }
  }
}
