@import "../../assets/scss/_fonts.scss";
@import "../../assets/scss/_variables.scss";
@import "../../assets/scss/_mixins.scss";

.loading {
	max-width: 85px;
	min-height: 350px;
	margin: auto;
	display: flex;
	flex-direction: column;
	justify-content: center;

	svg {
		circle {
				fill: $brandHoverBlue
		}
	}
}
