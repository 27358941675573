@import "../../../../../assets/scss/_variables"; 
@import "../../../../../assets/scss/_mixins"; 


.step-container {
	width: 100%;
	margin: 0 auto;
	margin-top: 30px;
	display: flex;
	flex-direction: column;
	align-items: center;
	border-radius: 15px;
	border: 1px solid lighten( #000000, 90%);
	padding: 35px;

	.step {
		display: flex;
		justify-content: center;
		align-items: center;

		@include tablet-medium {
		flex-direction: column
		}
	}

	h3 {
		@include title;
    color: $brandDeepblue;
		margin-bottom: 0px;
		font-size: 2rem;

		@include tablet-medium {
			margin-top: 20px;
		}
	}

	.step-right, .step-left {
		width: 50%;

		@include tablet-medium {
			width: 100%;
		}
	}

	.step-right {
		margin-right: 10px;
		display: flex;
    flex-direction: column;
		justify-content: space-evenly;
		height: max-content;

		p {
			font-size: 1rem;
		}
	}

	.step-left {
		margin-right: 60px;
		max-width: 450px;

		@include tablet-large {
			margin-right: 25px;
		}

		@include tablet-medium {
			margin-right: 0px;
		}
		
	}

	.actions {
      margin: 0 auto;
      margin-top: 15px;
	}
}
