@import "../../../../../assets/scss/fonts"; 
@import "../../../../../assets//scss/variables"; 
@import "../../../../../assets//scss/mixins"; 

.single-prize-container {
  margin-top: -45px;

  @include tablet-large {
    margin-top: -85px;
  }

  .card {
    width: 100%;
    max-width: 100%;

    .flex-container {
      display: flex;
      align-items: center;

      @include tablet-medium {
        flex-direction: column;
      }
    }
  }
}

.header-prize {
  @include tablet-large {
    display: flex;
    align-items: center;
  }

  .prize-image-container {
    @include tablet-large {
      width: 65px;
      margin-right: 20px;
      flex-shrink: 0;
    }
  }

  .optional-info {
    display: none;

    @include tablet-large {
      display: block;
      margin-top: 0px;
      text-transform: uppercase;
      color: $brandMediumLightBlue;
    }
  }
}


.friends-info{
  display: flex;
  align-items: center;
  position: relative;
  margin-bottom: 10px;
  top: 0px;

  @include desktop-small {
    font-size: 1rem;
  }

  @include tablet-large {
    max-width: 285px;
    margin-bottom: 50px;
    top: 40px;
  }

  @include tablet-small {
    max-width: 175px;
    top: 30px;
  }
  
  @include phone-large {
    left: 0px;
    max-width: 140px;
  }

}


.not-enough-friends {
    color: $brandMediumGrey;

    .not-enough {
      background: url(../../../../../assets/images/icons/sad-face-gry.svg) no-repeat;
      display: inline-block;
      height: 20px;
      margin-right: 5px;
      padding-left: 25px;
 
    }
  }

  .enough-friends {
    color: $brandCorrect;

    .enough {
      background: url(../../../../../assets/images/icons/smile-face-gr.svg) no-repeat;
      display: inline-block;
      height: 20px;
      margin-right: 5px;
      padding-left: 25px;
    }
  }

  .exchange-prizes-text {
    p{
      font-size: 1rem;
    }

    h2 {
      @include title;
      color: $brandDeepblue;
      margin-bottom: 0px;
    }

    button {
      background-image: url(../../../../../assets/images/icons/gift-tab-w.svg);
      background-repeat: no-repeat;
      background-position: 94% 12px;
      background-size: 20px;
      text-transform: uppercase;

      @include tablet-small {
        background-position: 94% 8px;
        background-size: 18px;
      }
    }
  }

  .exchange-prizes-image {
    width: 30%;
    flex-shrink: 0;
    margin-right: 30px;

    @include tablet-large {
      width: 60%;
    }
  }