@import "../../../../assets/scss/fonts.scss";
@import "../../../../assets/scss/_variables.scss";
@import "../../../../assets/scss/_mixins.scss";

.top-modal-info {
	h3 {
		margin-bottom: 0px !important;
	}
}

.radio-label {
}

.radio-group {
  margin-top: 20px;
	.textarea-content {
		padding-left: 10px;
		padding-right: 10px;
	}
}
