@import "../../assets/scss/_fonts.scss";
@import "../../assets/scss/_variables.scss";
@import "../../assets/scss/_mixins.scss";

.report-content {
	background-color: white;
	position: relative;
	padding-bottom: 30px;
	padding-top: 30px;

	@include phone-large {
		display: flex;
		justify-content: space-between;
		align-items: center;
		padding-bottom: 5px;
		padding-top: 12px;
	}

	&:last-child {
		border-bottom: 0px;
		padding-bottom: 0px;
	}

	.top {
		display: flex;
		justify-content: space-between;

		@include tablet-small {
			flex-direction: column;
		}
	}

	.action-buttons {
		display: flex;

		@media (max-width: 540px) {
			flex-direction: column;
		}

		.bottom {
			margin-top: 20px;

			.view-button {
				@include buttonApp;
				background-color: $brandDarkishblue;
				width: 48px;
				height: 48px;

				&:focus-visible {
					border: 0px;
					outline: 0px;
				}

				img {
					padding: 2px;
				}

				@media (max-width: 1280px) {
					width: 45px;
					height: 45px;
				}

				@media (max-width: 414px) {
					height: 34px !important;
					width: 34px !important;

					img {
						padding: 0;
					}
				}

				border-radius: 10px;
			}
		}
	}

	.id {
		color: $brandLightBlue;
		font-weight: bold;
		font-family: $title;
		margin: 0px;
	}

	.title {
		margin: 0px;
		color: $brandDeepblue;
		font-family: $title;
		font-size: 1.2rem;
	}

	.date {
		margin: 0px;
	}

	.price {
		color: $brandDeepblue;
	}

	.report-download {
		@include buttonApp;
	}

	.button-app {
		width: 168px;
		display: flex;
		justify-content: space-between;

		&:hover .button-icon {
			//transform: translateX(-5px);
		}

		@include desktop-large {
			width: 168px;
		}

		@include phone-large {
			width: 32px;
			height: 32px;
			border-radius: 10px;
		}

		.button-label {
			margin-left: 10px;

			@include phone-large {
				display: none;
			}

			.button-icon {
				margin-right: 10px;
				transition: transform 300ms ease-in-out;
			}
		}
	}

	.btn_protocol {
		width: 160px;

		@include desktop-large {
			width: 160px;
		}

		@include desktop-medium {
			width: 145px;
		}

		@include tablet-medium {
			width: 140px;
		}

		@include tablet-small {
			width: 130px;
		}

		@include phone-large {
			width: 32px;
		}

		@include phone-small {
			width: 32px;
		}
	}

	.spacer {
		margin-right: 20px;
	}
}