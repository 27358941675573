@import "../../assets/scss/fonts"; 
@import "../../assets/scss/variables"; 
@import "../../assets/scss/mixins"; 

.drag-and-drop-container {
    display: inline-block;
		position: relative;
		margin-top: 20px;
		width: 100%;
		
	.drag-and-drop-overlay {
		border: dashed 2px;
		border-color: lighten($brandMediumGrey, 15%);
    height: 200px;
		width: 100%;
		margin: auto;
    max-width: 400px;
		border-radius: 25px;
		display: flex;
		justify-content: center;
		align-items: center;

		&.is-dragging {
			background-color: #eaeaea;
		}
		
		.drag-and-drop-text {
			text-align: center;
			color: lighten($brandMediumGrey, 15%);
			display: flex;
			justify-content: center;
			align-items: center;
			flex-direction: column;

			& p:nth-child(1) {
				font-family: $title;
				font-weight: bold;
				font-size: 1.3rem;
    		margin-bottom: 0px;
			}

			& p:nth-child(2) {
    		margin-top: 0px;
			}

			.drag-drop-icon {
				max-width: 40px;
			}
		}
	}
}
