//COMMONS
@mixin layout {
  background-color: #f0f9ff;
  background: url(../img/fondo.jpg) no-repeat;
  background-position: center -95px;
  background-size: 1170px;
}

@mixin text {
  font-family: $text;
  font-weight: normal;
  font-style: normal;
}

@mixin title {
  font-family: $title;
  font-weight: bold;
  font-style: normal;
  font-size: 2.25rem;
}

@mixin flexColumnCenter {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

@mixin buttonApp {
  background-color: $brandOrange;
  border: none;
  font-size: 1.1rem;
  font-family: $menu;
  border-radius: $borderRadius;
  color: $brandWhite;
  display: inline-block;
  padding: 10px;
  cursor: pointer;
  line-height: 1.8rem;

  &:hover {
    background-color: $brandDarkOrange;
  }
}

@mixin inputApp {
  border-radius: $borderRadius;
  border: none;
  background-color: $brandLightGrey;
  line-height: 1.8rem;
  padding: 12px;
  width: 100%;
  font-family: $text;
  font-size: 1rem;
}

@mixin iconsBackground {
  background-color: $brandHoverBlue !important;
  background-repeat: no-repeat !important;
  background-size: 30px !important;
  background-position: center !important;
}

///DEVICES
@mixin phone-small {
  @media (max-width: 320px) { @content; }
}

@mixin phone-large {
  @media (max-width: 414px) { @content; }
}

//TABLET
@mixin tablet-small {
  @media (max-width: 540px) { @content; }
}

@mixin tablet-small-landscape {
  @media (max-width: 540px) and (orientation: landscape) { @content; }
}

@mixin tablet-medium {
  @media (max-width: 768px) { @content; }
}

@mixin tablet-large {
  @media (max-width: 980px) { @content; }
}

@mixin tablet-large-portrait {
  @media (max-width: 1024px) and (orientation: portrait) { @content; }
}

//DESKTOP
@mixin desktop-small {
  @media (max-width: 1080px) { @content; }
}

@mixin desktop-medium {
  @media (max-width: 1280px) { @content; }
}

@mixin desktop-large {
  @media (max-width: 1500px) { @content; }
}

