@import "../../../../assets/scss/fonts";
@import "../../../../assets/scss/_variables.scss";
@import "../../../../assets/scss/_mixins.scss";

.friends-indicator-container {
  p {
    margin-top: 0px;
    margin-bottom: 10px;

    @include tablet-medium {
      margin-bottom: 5px;
    }
  }

  .friends-list {
    display: flex;
    justify-content: flex-end;
    
    li {
      width: 40px;
      padding: 2px;

      @include tablet-medium {
        width: 35px;
      }

      @include tablet-small {
        width: 32px;
      }

      @include phone-large {
        width: 22px;
        padding: 1px;
      }
    }
  }
}
