@import "../../../../assets/scss/_fonts.scss";
@import "../../../../assets/scss/_variables.scss";
@import "../../../../assets/scss/_mixins.scss";

div.card.appointment-card {
  border: 1px solid $brandLightGrey;
  font-size: 0.9rem;
  max-height: 300px;
  min-height: 300px;
  max-width: 100%;
  border-radius: 1em;
  padding: 10%;
  display: flex;
  flex-direction: column;
  justify-content: center;  width: 100%;

  @include phone-large {
    padding: 20px;
    padding-bottom: 2%;
    margin-bottom: 20px;
    max-height: 230px;
    min-height: 230px;
  }

  .date-container {
    display: flex;
    margin-bottom: 12px;

    @include phone-large {
      margin-bottom: 0px;
    }

    .date {
      @include title;
      color: $brandDeepblue;
      font-size: 21px;
      margin-top: 0px;

      .date-info {
              @include desktop-small {
                font-size: 0.8rem;
              }

      }
    }
  }

  .icon {
    margin-right: 10px;
    
    img{
      width: 45px;

      @include desktop-small {
        width: 35px;
      }
    }
  }

  .icon-info {
    display: flex;
    align-items: center;
    margin-bottom: 10px;

    @include phone-large {
      margin-bottom: 5px;
    }

    img {
      max-width: 23px;
      margin-right: 10px;

      @include phone-large {
        max-width: 21px;
      }
    }
  }

  .button-app {
    font-size: 0.9rem;
    padding: 6px;
    margin-top: 12px;

    .button-icon {
      top: 9px;
    }
  }

  .month {
    padding-left: 5px;
  }

}

//CITAS LENGHT
.citas-length-1 {
  // .card.appointment-card {
  //   width: 460px;
  //   margin: auto;
  //   padding: 5%;
  //   max-height: 100%;
  // }
}

.citas-length-2{
  .card.appointment-card {
    padding: 10%;
    width: 90%;

    @include phone-large {
      width: 100%;
      padding: 20px;
    }
  }
}



