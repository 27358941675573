@import "../../assets/scss/_fonts.scss";
@import "../../assets/scss/_variables.scss";
@import "../../assets/scss/_mixins.scss";


.app-header {
	//	height: 220px;
	padding: 25px;
	display: flex;
	flex-direction: row-reverse;
	justify-content: space-between;
	position: relative;

	.header-actions {
		position: relative;
		height: 50px;
		display: flex;
		flex-direction: row-reverse;
		align-items: center;
		justify-content: space-between;
		gap: 25px;

		@include tablet-small {
			position: absolute;
			right: 0;
			top: 0px;
		}

		.select_ontainer_in_header {
			display: block;

			@include tablet-medium {
				display: none;
			}
		}

	}

	@include desktop-large {
		//		height: 175px;
	}

	@include desktop-small {
		//		height: 150px;
	}

	@include tablet-small {
		padding: 0px;
		padding-top: 5%;
		height: auto;
		margin-bottom: 8%;
		display: block;
	}

	.update-info {
		width: 50%;
		left: 0;
		bottom: 0;
		left: 40px;

		@include desktop-large {
			top: 25px;
		}

		@include tablet-small {
			top: 75px !important;
		}
	}

	.logo {
		height: 3.2rem;
		width: auto;
		max-width: 250px;
		display: block;
	}

	.logo-mobile {
		max-width: 180px;
		margin-top: 10px;
	}

	.hello-text {
		margin-top: 0;

		@include tablet-small {
			display: flex;
			margin-top: 3%;
			margin-left: 3%;
			flex-direction: column;
		}

		@include phone-large {
			margin-left: 3%;
		}

		@include phone-small {
			margin-left: 3%;
		}
	}

	.greeting {
		font-family: $title;
		font-size: 2rem;

		@include desktop-large {
			font-size: 2.4rem;
		}

		@include tablet-small {
			font-size: 1.2rem;
			color: #606060;
		}

		@include phone-small {
			font-size: 1rem;
		}
	}

	.user-name {
		@include title;
		text-transform: uppercase;
		font-weight: 300;
		font-size: 2rem;

		@include desktop-large {
			font-size: 2rem;
		}

		@include tablet-small {
			font-size: 1.3rem;
			padding-left: 0px;
			color: #606060;
			padding-top: 2px;
		}

		@include phone-small {
			font-size: 1.4rem;
			padding-left: 0px;
		}
	}

	p {
		margin: 0px;
	}

	.logout {
		background: url(../../assets/images/icons/sign-out-g.svg) no-repeat;
		background-position: right center;
		background-size: 15px;
		width: 115px;
		height: 15px;
		font-size: .9rem;
		cursor: pointer;

		@include desktop-medium {
			width: 100px;
		}

		@include tablet-large {
			width: 100px;
		}

		@include tablet-small {
			width: 85px;
			position: absolute;
			right: 0;
			top: 25px;
		}

		@include phone-large {
			width: 82px;
			top: 15px;
			padding-top: 2px;
		}

		&:hover {
			color: $brandOrange;
			background: url(../../assets/images/icons/sign-out-o.svg) no-repeat;
			background-position: right center;
		}
	}

	.upload-file-button {
		background-color: $brandDeepblue;
		background-image: url(../../assets/images/icons/upload-file2-w.svg);
		background-repeat: no-repeat;
		background-position: 95% center;
		background-size: 20px;
		font-size: .9rem;
		padding: 10px 15px;
		border-radius: 10px;
		line-height: 1.3rem;
		width: 180px;
		display: block;

		&:hover {
			background-color: $brandOrange;
		}

		@include tablet-large {
			width: 160px;
		}

		@include tablet-small {
			margin-top: 90px;
		}

		@include phone-large {
			background-image: none;
			width: max-content;
			height: max-content;
			margin-top: 60px;
			padding: 6px 15px;
			border-radius: 8px;
		}

		.link-button-icon {
			display: none;
		}
	}

	.clinic_load_data_modal_container {
		display: flex;
		flex-direction: column;
		align-items: center;
		padding: 0px 10px;
		max-width: 580px;

		@include phone-large {
			max-width: 400px;
			padding: 0px 0px;
		}

		.loading {
			min-height: 120px;
		}
	}
}