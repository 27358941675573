@import "../../../../assets/scss/_fonts.scss";
@import "../../../../assets/scss/_variables.scss";
@import "../../../../assets/scss/_mixins.scss";

// Today tiene que ir debajo de highlighted
// .CalendarDay__highlighted_calendar {
//   background: red;
//   color: #484848;
// }
.CalendarDay {
  cursor: auto !important;

  &:focus {
    outline: 0px;
  }
}

.DayPicker {
  box-shadow: none !important;
  color: $brandDeepblue;
}

.CalendarDay__default, .DayPickerNavigation_button__default {
  border: none;
}

.CalendarDay__default:hover {
  background: transparent;
  border: none;
  border-radius: 100%;
  cursor: pointer;
  // pointer-events: none;
}

.CalendarDay__highlighted_calendar {
  background-color: $brandOrange !important;
  border-radius: 100%;
  color: $brandWhite;

  &:hover {
    border: none;
    border-radius: 100%;
    cursor: pointer !important;
    // pointer-events: none;
    color: $brandWhite !important;
  }
}

.CalendarDay__today {
  background-color: $brandMediumLightBlue !important;
  color: $brandWhite !important;
  border-radius: 100%;
  &:hover {
    cursor: pointer !important;
  }
}

.DayPicker_weekHeader_li {
  text-transform: uppercase;
  color: $brandMediumLightBlue;
}

.CalendarMonth_caption {
  color: $brandDeepblue;
  font-family: $title;
  font-size: 1.2rem;
  padding-left: 0px;
  padding-right: 0px;
  text-transform: capitalize;
}

// NOTE: the order of these styles DO matter

// Will edit everything selected including everything between a range of dates
.CalendarDay__selected_span {
  background: #82e0aa; //background
  color: white; //text
  border: 1px solid red; //default styles include a border
}

// Will edit selected date or the endpoints of a range of dates
.CalendarDay__selected {
  background: blue;
  color: white;
}

// Will edit when hovered over. _span style also has this property
.CalendarDay__selected:hover {
  background: orange;
  color: white;
}

// Will edit when the second date (end date) in a range of dates
// is not yet selected. Edits the dates between your mouse and said date
.CalendarDay__hovered_span:hover,
.CalendarDay__hovered_span {
  background: brown;
}

.DayPickerNavigation_button:hover {
  border: none;
}
