@import "../../../assets/scss/_fonts.scss";
@import "../../../assets/scss/_variables.scss";
@import "../../../assets/scss/_mixins.scss";

.dates-container {
	margin-left: -2%;
	
	@include phone-large {
		margin-left: 0px;
	}

	.subtitle {
		@include phone-large {
			padding-top: 40px !important;
			margin-bottom: 5px;
		}
	}

	.update-info {
		top: 30px;
		right: 40px;

		@include phone-large {
			top: 20px !important;
			right: 30px !important
		}
	}

	.card-container {
		display: flex;
		justify-content: space-between;
		align-items: flex-start;
		
		@include tablet-large {
			flex-direction: column;
			align-items: center;
		}
	}

	.appointments-container{
		display: grid;
		grid-template-columns: repeat(3, 30%);
		padding-top: 30px;
		width: 62%;
		column-gap: 25px;
		row-gap: 25px;
		
		@include desktop-large {
			grid-template-columns: repeat(2, 46%);
		}

		@include tablet-large {
			width: 80%;
			grid-template-columns: repeat(2, 50%);
		}

		@include tablet-small {
			width: 100%;
			grid-template-columns: repeat(2, 48%);
		}

		@include phone-large {
			display: block;
			width: 90% !important;
			margin: auto;
		}

		&.citas-length-0 {
			display: block;
			max-width: 90%;
			margin-left: auto;
			margin-right: auto;
	}

		&.citas-length-1 {
				display: block;
				max-width: 280px;
				margin-left: auto;
				margin-right: auto;
		}

		&.citas-length-2 {
				grid-template-columns: repeat(2, 50%) !important;
				column-gap: 0px;
				width: 60%;

				@include tablet-large {
					width: 90%;
				}

				@include tablet-small {
					width: 100%;
				}
		}

		.no-calendar.empty-container {
			width: 60%;
			justify-content: flex-start;
            margin-top: 0;
		}
	}
}

