@import "../../../../assets/scss/_fonts.scss"; 
@import "../../../../assets/scss/_variables.scss"; 
@import "../../../../assets/scss/_mixins.scss";

.steps-container {
	display: flex;
	flex-direction: column;

	h2{
		@include title;
		color: $brandDeepblue;
		margin-top: 50px;
		margin-bottom: -20px;
	}

	.button-app {
		@include tablet-small {
			width: 100%;
			max-width: 95%;
			//height: 72px;
			// background: url(../../../../assets/images/icons/arrow-right-w.svg) no-repeat;
			background-position: right center;
			background-size: 25px;
		}

		// @include phone-large {
		// 	height: 66px;
		// }

		// @include phone-small {
		// 	height: 50px;
		// }

		.button-icon {
			@include tablet-small {
				display: none;
			}
		}

		&:hover {
			@include tablet-small {
				color: $brandOrange;
			}
		}
	}
}
