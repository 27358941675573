@import "../../assets/scss/fonts.scss";
@import "../../assets/scss/_variables.scss";
@import "../../assets/scss/_mixins.scss";

.date-box {
  cursor: pointer;
  border: 1px solid $brandOrange;
  width: 80px;
  border-radius: 15px;
  margin-left: 5px;
  margin-right: 5px;
  display: flex;
  flex-direction: column;
  height: 100px;
  padding-top: 12px;
  padding-bottom: 12px;

  @include desktop-small {
    height: 90px;
  }

  p {
    margin: 0;
  }

  .day-number {
    font-family: $title;
    font-size: 2.5rem !important;
    font-weight: bold;
    color: $brandDeepblue;
  }

  .day-name {
    color: $brandDeepblue;
    font-size: 0.8rem !important;
  }

  &:hover {
    .day-name, .day-number {
      color: $brandOrange;
    }
  }
}

.date-selected, .date-selected:hover{
  background-color: $brandOrange !important;

  .day-name, .day-number {
    color: $brandWhite;
  }
}


